import axios from 'axios';
import useSWR, { mutate } from 'swr';
// 글로벌 리프레시 상태 저장 변수
export let globalRefreshPromise: Promise<string> | any = null;
let globaltokenPromise: Promise<string> | any = null;
let pendingMutations: Set<string> = new Set();
export const agreeTermsRequest = async (email: string, connectType: 'new' | 'exist' | undefined) => {
    try {
        const re = await axios.post(`/api/kb/terms?email=${email}`, {
            connectType: connectType
        });
        const status = re.status;
        return status;
    }
    catch (error: any) {
        console.error('Failed to agree terms:', error);
        console.error('error:', error.response);
        return error.response.status;
    }
}

export const sorRequest = async (email: string, method?: string, setKbAuth?: any) => {
    try {
        const re = await kbFetcherWithJWT(`/api/kb/sor?email=${email}`, {
            method: method || 'GET',
        }, email, setKbAuth);
        return re;

    }
    catch (error: any) {
        console.error('Failed to sorRequest:', error);
        return error.response;
    }
}


// Helper function to check if a response is unauthorized (401)
const isUnauthorized = (error: any) => {
    console.log("isUnauthorized: ", error.response.status === 401);
    return error.response && error.response.status === 401;
};

const isForbidden = (error: any) => {
    console.log("isForbidden: ", error.response.status === 403);
    return error.response && error.response.status === 403;
}
const isTokenError = (error: any) => {
    console.log("isTokenError: ", error.response.status === 405);
    return error.response && error.response.status === 405;
}
const isAccidentAccount = (error: any) => {
    console.log("isAccidentAccount: ", error.response.status === 423);
    return error.response && error.response.status === 423;
}

const isNotTermsAgreed = (error: any) => {
    return error.response && error.response.status === 422;
}
const isPasswordCacheError = (error: any) => {
    return error.response && error.response.status === 408;
}

const kbFetcherWithJWT = async (url: string, options: any = {}, email: string, setKbAuth?: any) => {


    try {

        const response = await axios({
            url,
            method: options.method || 'GET',
            headers: options.headers || {},
            data: options.body || null,
            withCredentials: true, // Include cookies in the request
        });
        console.log("response: ", response);
        return response.data;
    } catch (error: any) {
        console.log("isTokenError(error): ", isTokenError(error));
        console.log("error ", error);
        console.log("isNotTermsAgreed(error): ", isNotTermsAgreed(error));

        if (isUnauthorized(error) || isTokenError(error)) { // 토큰이 아예 없는 경우
            setKbAuth({
                status: 'loading',
            });
            pendingMutations.add(url); // 실패한 요청 URL 저장
            try {
                if (!globaltokenPromise) {
                    globaltokenPromise = true;
                    await axios.post(`/api/kb/auth?email=${email}&refetch=true`, {}, { withCredentials: true });
                    const retryResponse = await axios({
                        url,
                        method: options.method || 'GET',
                        headers: options.headers || {},
                        data: options.body || null,
                        withCredentials: true,
                    });

                    pendingMutations.forEach((url) => mutate(url)); // 모든 실패한 요청 재시도
                    pendingMutations.clear(); // 저장된 URL 초기화
                    setKbAuth({
                        status: true,
                    });
                    globaltokenPromise = null;

                    return retryResponse.data;

                }
                else {
                }



            } catch (error) {
                // 약관 동의를 하지 않은 경우

                console.error('Failed to access token:', error);
                throw error;
            }
        }
        else if (isForbidden(error)) {
            console.log("리프레시 토큰을 통한 재발급 실행: ");
            //리프레시 토큰을 통한 재발급 실행
            if (setKbAuth) {
                setKbAuth({
                    status: 'loading',
                });
            }

            pendingMutations.add(url); // 실패한 요청 URL 저장
            try {
                if (!globaltokenPromise) {
                    globaltokenPromise = true;
                    await axios.post(`/api/kb/refresh`, {}, { withCredentials: true });
                    const retryResponse = await axios({
                        url,
                        method: options.method || 'GET',
                        headers: options.headers || {},
                        data: options.body || null,
                        withCredentials: true,
                    });


                    const timeout = await new Promise((resolve) => setTimeout(resolve, 200));
                    await timeout;

                    pendingMutations.forEach((url) => { console.log('mutate url', url); mutate(url); }); // 모든 실패한 요청 재시도
                    pendingMutations.clear(); // 저장된 URL 초기화
                    if (setKbAuth) {
                        setKbAuth({
                            status: true,
                        });
                    }

                    globaltokenPromise = null;

                    return retryResponse.data;

                }
                else {
                }

            } catch (refreshError: any) {
                console.error('Failed to refresh token:', refreshError);
                try {
                    await axios.post(`/api/kb/auth?email=${email}`, {}, { withCredentials: true });
                    // Retry the original request after refreshing the token
                    const retryResponse = await axios({
                        url,
                        method: options.method || 'GET',
                        headers: options.headers || {},
                        data: options.body || null,
                        withCredentials: true,
                    });
                    return retryResponse.data;
                } catch (e) {

                    throw refreshError?.response?.data || e;
                }
            }
        }
        else if (isNotTermsAgreed(error)) {
            console.log("isNotTermsAgreed: ", error);
            return { needTermsAgree: true };
        }

        else if (isAccidentAccount(error)) {
            return { accidentAccount: true };
        }
        else if (isPasswordCacheError(error)) {
            return { passwordCacheError: true };
        }
        else {
            pendingMutations.clear();
            console.error('kbRequest 실패:', error);
            throw error;
        }


    }
};

export const kbFetcherWithJWTV2 = async (url: string, options: any = {}, email: string) => {


    try {

        const response = await axios({
            url,
            method: options?.method || 'GET',
            headers: options?.headers || {},
            data: options?.body || null,
            withCredentials: true, // Include cookies in the request
        });
        return response
    } catch (error: any) {

        if (isUnauthorized(error)) { // 토큰이 아예 없는 경우
            try {

                globaltokenPromise = await axios.post(`/api/kb/auth?email=${email}`, {}, { withCredentials: true });

                const retryResponse = await axios({
                    url,
                    method: options.method || 'GET',
                    headers: options.headers || {},
                    data: options.body || null,
                    withCredentials: true,
                });

                return retryResponse;
            } catch (error: any) {
                console.log("isUnauthorized error: ", error);
                return error.response;

                // 약관 동의를 하지 않은 경우
                // if (isNotTermsAgreed(error)) {
                //     return { needTermsAgree: true };
                // }
                console.error('Failed to access token:', error);
                throw error;
            }
        }
        else if (isForbidden(error)) {
            //리프레시 토큰을 통한 재발급 실행
            try {
                globalRefreshPromise = axios.post('/api/kb/refresh', {}, { withCredentials: true });

                const retryResponse = await axios({
                    url,
                    method: options.method || 'GET',
                    headers: options.headers || {},
                    data: options.body || null,
                    withCredentials: true,
                });

                return retryResponse;

            } catch (refreshError: any) {
                console.error('Failed to refresh token:', refreshError);
                try {
                    await axios.post(`/api/kb/auth?email=${email}`, {}, { withCredentials: true });
                    // Retry the original request after refreshing the token
                    const retryResponse = await axios({
                        url,
                        method: options.method || 'GET',
                        headers: options.headers || {},
                        data: options.body || null,
                        withCredentials: true,
                    });
                    // return retryResponse.data;
                    return retryResponse;


                } catch (e) {
                    return error.response || refreshError?.response?.data || e
                }
            }
        }

        else if (isAccidentAccount(error)) {
            return { accidentAccount: true };
        }

        console.log("error: ", error);
        return error.response;

    }
};


export default kbFetcherWithJWT;
