import { useSession } from "next-auth/react";
import useSWR from "swr";
import kbFetcherWithJWT, { globalRefreshPromise, kbFetcherWithJWTV2 } from "./kbAuth";
import { updatePasswordToRedis } from "contexts/KBContext";
import axios from "axios";
import { useRecoilState } from "recoil";
import { kbAuthState } from "states/KbState";
import { useEffect } from "react";


interface CustomSWRResponse<Data, Error> {
    data: any;
    isLoading: boolean;
    mutate: (data?: Data | Promise<Data>, shouldRevalidate?: boolean) => Promise<Data | undefined>;
    error: Error | undefined;
    isTermsAgreedError: boolean;
}

export const useCustomSWR = (
    key: string | null,
    fetcher: (url: string) => Promise<any>,
    options?: any,
): any => {

    const { data, error, isValidating, mutate } = useSWR(key, fetcher, options);

    // 약관 동의 에러 판단
    const isTermsAgreedError = data?.needTermsAgree === true;
    const isAccidentAccount = data?.accidentAccount === true;
    return {
        data,
        isValidating: !data && isValidating,
        mutate,
        error,
        isTermsAgreedError,
        isAccidentAccount,
    };
};


export async function isAccidentAccount(accountNumber: string, password: string, email: string) {

    const res = await kbFetcherWithJWTV2(`/api/kb/account/isaccident?displayAccountNo=${accountNumber}&password=${password}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        },
        email

    );
    return res.status === 423;
}


// 연동 되지 않은 계좌 목록 조회(0202)
const useAccountCheck = () => {
    const { data: session }: any = useSession();
    const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
    const auth = async (email: string) => {
        try {
            await axios.post(`/api/kb/auth?email=${email}`, {}, { withCredentials: true });
            setKbAuth({
                status: true,
            });
        } catch (error: any) {
            setKbAuth({
                status: false,
                error: error?.response?.data?.error || error?.response?.data,
                isTermsError: error?.response?.status === 422,
            });
        }
    }
    useEffect(() => {
        if (session?.user?.email) {
            if (!kbAuth) {
                auth(session?.user?.email);
            }
        }
    }, [session, kbAuth]);


    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && kbAuth ? `/api/kb/account/check?email=${session?.user?.email}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });
    const isLoading = !data && isValidating;
    return [data, isLoading, mutate, error, isTermsAgreedError, kbAuth, setKbAuth];
}

// 특정 계좌번호의 계좌 정보 조회(0202)
export const useSpecificAccountCheck = (accountNumber?: string) => {
    const { data: session }: any = useSession();
    // console.log("accountNumber", accountNumber);
    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && accountNumber ? `/api/kb/account/check?email=${session?.user?.email}&account=${accountNumber}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false, // 에러 발생 시 재시도 X
    });
    const isLoading = !data && isValidating;
    const account = data?.accounts[0] || [];
    return [[{ ...account }], isLoading, mutate, error, isTermsAgreedError];
}




// 계좌 목록 조회(RDB)
export const useAccountList = () => {
    const { data: session }: any = useSession();
    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session ? `/api/kb/account/list?email=${session?.user?.email}` : null,
        (url: string) => kbFetcherWithJWT(url, {}, session?.user?.email),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false, // 에러 발생 시 재시도 X

        });
    const isLoading = !data && isValidating;
    const account = data?.accounts || [];
    return [account, isLoading, mutate, error, isTermsAgreedError];
}

// 계좌의 주문가능현금 + 티커의 잔고 수량 조회
export const useKbAccountBalance = (accountNumber: string, password: string, ticker?: string) => {
    const { data: session }: any = useSession();
    const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
    const auth = kbAuth && kbAuth.status !== 'loading' && !kbAuth?.isTermsError



    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && accountNumber && password && auth ? `/api/kb/account/balance?email=${session?.user?.email}&displayAccountNo=${accountNumber}&password=${password}&ticker=${ticker}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email, setKbAuth),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false, // 에러 발생 시 재시도 X
            dedupingInterval: 0,

        });
    const isLoading = isValidating;
    const accountData = data?.accountData || null;
    return [accountData, isLoading, mutate, error, isTermsAgreedError];
}

//수수료율 조회
export const useChargeData = (accountNumber: string, password: string, amount: number) => {
    const { data: session }: any = useSession();
    const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
    const auth = kbAuth && kbAuth.status !== 'loading' && !kbAuth?.isTermsError

    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && accountNumber && password && auth ? `/api/kb/account/tradeCharge?email=${session?.user?.email}&displayAccountNo=${accountNumber}&password=${password}&amount=${amount}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email, setKbAuth),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false, // 에러 발생 시 재시도 X
            dedupingInterval: 0,

        });
    const isLoading = !data && isValidating;
    const chargeData = data?.chargeData || null;
    return [chargeData, isLoading, mutate, error, isTermsAgreedError];
}

// 보유종목+ 개별 평가금 조회
export const useKbTotalValuations = (accountNumber: string) => {
    const { data: session }: any = useSession();
    const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
    const auth = kbAuth && kbAuth.status !== 'loading' && !kbAuth?.isTermsError

    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && accountNumber && auth ? `/api/kb/account/totalHoldings?email=${session?.user?.email}&displayAccountNo=${accountNumber}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email, setKbAuth),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false, // 에러 발생 시 재시도 X

        });
    const isLoading = !data && isValidating;
    const stockList = data ? [...data] : [];
    return [stockList, isLoading, mutate, error, isTermsAgreedError];
}

// 전체 평가금 조회 (total, 국내, 해외)
export const useKbTotalEvalAmout = (accountNumber: string) => {
    const { data: session }: any = useSession();
    const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
    const auth = kbAuth && kbAuth.status !== 'loading' && !kbAuth?.isTermsError
    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && accountNumber && auth ? `/api/kb/account/evalAmout?email=${session?.user?.email}&displayAccountNo=${accountNumber}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email, setKbAuth),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false, // 에러 발생 시 재시도 X

        });
    const isLoading = !data && isValidating;
    const totalValuation = data || null;
    return [totalValuation, isLoading, mutate, error, isTermsAgreedError];
}


//거래내역 조회
export const useKbTransactionList = (accountNumber: string, password: string, startDate: string, endDate: string) => {
    const { data: session }: any = useSession();
    const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
    const auth = kbAuth && kbAuth.status !== 'loading' && !kbAuth?.isTermsError

    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && accountNumber && password && auth ? `/api/kb/trade?email=${session?.user?.email}&displayAccountNo=${accountNumber}&password=${password}&startDate=${startDate}&endDate=${endDate}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email, setKbAuth),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false, // 에러 발생 시 재시도 X
            dedupingInterval: 0,

        });
    const isLoading = !data && isValidating;
    const transactionList = data?.accountData || null;
    const openDate = data?.openDate || null;
    return [transactionList, openDate, isLoading, mutate, error, isTermsAgreedError];
}

// 주문내역 조회
export const useKbOrderList = (accountNumber: string, password: string, startDate: string, endDate: string) => {
    const { data: session }: any = useSession();
    const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
    const auth = kbAuth && kbAuth.status !== 'loading' && !kbAuth?.isTermsError

    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && accountNumber && password && auth ? `/api/kb/order?email=${session?.user?.email}&displayAccountNo=${accountNumber}&password=${password}&startDate=${startDate}&endDate=${endDate}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email, setKbAuth),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false, // 에러 발생 시 재시도 X
            dedupingInterval: 0,

        });
    const isLoading = !data && isValidating;
    const orderList = data?.accountData || null;
    const reserveOrderList = data?.reserveOrderList || null;
    const openDate = data?.openDate || null;
    return [orderList, reserveOrderList, openDate, isLoading, mutate, error, isTermsAgreedError];
}

export const useKbWaitingOrderList = (accountNumber: string, password: string) => {
    const { data: session }: any = useSession();
    const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
    const auth = kbAuth && kbAuth.status !== 'loading' && !kbAuth?.isTermsError

    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && accountNumber && password && auth ? `/api/kb/waitingOrder?email=${session?.user?.email}&displayAccountNo=${accountNumber}&password=${password}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email, setKbAuth),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false, // 에러 발생 시 재시도 X
            dedupingInterval: 0,

        });
    const isLoading = !data && isValidating;
    const waitginOrderList = data?.waitginOrderList ? data?.waitginOrderList : data?.passwordCacheError ? data : null;

    return [waitginOrderList, isLoading, mutate, error, isTermsAgreedError];
}
export const useKbCompleteOrderList = (accountNumber: string, password: string, startDate: string, endDate: string) => {
    const { data: session }: any = useSession();
    const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
    const auth = kbAuth && kbAuth.status !== 'loading ' && !kbAuth?.isTermsError

    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && accountNumber && password && auth ? `/api/kb/completeOrder?email=${session?.user?.email}&displayAccountNo=${accountNumber}&password=${password}&startDate=${startDate}&endDate=${endDate}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email, setKbAuth),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false, // 에러 발생 시 재시도 X
            dedupingInterval: 0,

        });
    const isLoading = !data && isValidating;
    const completeOrderList = data?.completeOrderList ? data?.completeOrderList : data?.passwordCacheError ? data : null;
    const openDate = data?.openDate || null;
    return [completeOrderList, openDate, isLoading, mutate, error, isTermsAgreedError];
}
export const useKbOrderDetail = (accountNumber: string, password: string, orderId: string, orderDt: string, tm: string) => {
    const { data: session }: any = useSession();
    const [kbAuth, setKbAuth] = useRecoilState(kbAuthState);
    const auth = kbAuth && kbAuth.status !== 'loading' && !kbAuth?.isTermsError;

    const {
        data,
        isValidating,
        error,
        mutate,
        isTermsAgreedError
    } = useCustomSWR(session && accountNumber && password && auth ? `/api/kb/order/${orderId}?email=${session?.user?.email}&displayAccountNo=${accountNumber}&password=${password}&ordrDt=${orderDt}&ordrTm=${tm}` : null,
        (url) => kbFetcherWithJWT(url, {}, session?.user?.email, setKbAuth),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false, // 에러 발생 시 재시도 X
            dedupingInterval: 0,
        });
    const isLoading = !data && isValidating;
    const orderDetail = data?.targetData ? data?.targetData : data?.passwordCacheError ? data : null;
    return [orderDetail, isLoading, mutate, error, isTermsAgreedError];
}


export default useAccountCheck;