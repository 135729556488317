import { ReactNode, useEffect, useState } from "react";
import TopBar from "components/layouts/w/TopBar";
import MenuBar from "components/layouts/w/MenuBar";
import Footer from "components/layouts/w/Footer";
import MobileTopBar from "components/layouts/m/TopBar";
import MobileFooter from "components/layouts/m/MenuBar";
import { useRouter } from "next/router";
import NotiFooter from "./m/Footer/noti";
import { useRecoilState } from "recoil";
import { isMobileState } from "states/deviceState";
import B2bFooter from "./w/Footer/b2b";
import SearchModal from "components/templates/common/Detail/SearchModal";

interface Props {
  isMobile: boolean;
  children?: ReactNode;
}

const Layout = ({ isMobile, children }: Props) => {
  const [, setIsMobile] = useRecoilState(isMobileState);

  useEffect(() => {
    setIsMobile(isMobile);
  }, []);

  const router = useRouter();

  const isMainPage = router.pathname === "/";
  const isDetailPage = router.pathname.startsWith("/detail/");
  const isSignupPage = router.pathname.startsWith("/signup");
  const isSearchPage = router.pathname.startsWith("/search");
  const isSearchListPage = router.pathname.startsWith("/search-list");
  const isHighRiskPage = router.pathname.startsWith("/high-risk");
  const isAssetPage = router.pathname.startsWith("/asset");
  const isRealtimeRiskPage = router.pathname.startsWith("/realtime-risk");
  const isCountryPage = router.pathname.startsWith("/country-risk");

  const isPortfolioPage = router.pathname.startsWith("/portfolio");
  const isPortfolioDetailPage = router.pathname === "/portfolio/detail";
  const isLoginPage = router.pathname.startsWith("/login");
  const isProfilePage = router.pathname.startsWith("/sevics");
  const isProfileAlarmPage = router.pathname.startsWith("/sevics/alarm");
  const isWathlistPage = router.pathname === "/watchlist";
  const isWatchlistEditPage = router.pathname === "/watchlist/edit";
  const isTolerancePage = router.pathname.startsWith("/tolerance");
  const isLearnPage = router.pathname.startsWith("/learn");
  const isIndustryPage = router.pathname.startsWith("/industry");
  const isAlarmMethodPage = router.pathname === "/alarm-method";
  const isPortfolioContentsPage = router.pathname.startsWith(
    "/portfolio/detail/contents"
  );
  const isPortfolioRiskListPage = router.pathname.startsWith(
    "/portfolio/detail/risk-list"
  );
  const isPortfolioRiskComparePage = router.pathname.startsWith(
    "/portfolio/detail/risk-compare"
  );

  const isPortfolioSharpesPage = router.pathname.startsWith(
    "/portfolio/detail/sharpe"
  );

  const isPortfolioOptimizePage = router.pathname.startsWith(
    "/portfolio/detail/optimize"
  );

  const isMainRankingPage = router.pathname.startsWith("/ranking");
  const isStrategyPage = router.pathname.startsWith("/strategy");

  const isB2bPage = router.pathname.startsWith("/b2b");
  const isDiagnosticPage =
    router.pathname.includes("/fin") ||
    router.pathname.includes("/mmt") ||
    router.pathname.includes("/cvar") ||
    router.pathname.includes("/sharpe");

  const [searchModal, setSearchModal] = useState(false);
  const isServicePage = router.pathname.startsWith("/service");
  const isMembershipPage = router.pathname.startsWith("/membership");
  const isKbAccountDetailPage = router.pathname.startsWith("/kb/accountdetail");

  const isTopicPage = router.pathname.startsWith("/topic");
  const isScoreRankingPage = router.pathname.startsWith("/scoreranking");
  const isIndustryRankingPage = router.pathname.startsWith("/industryranking");

  return (
    <>
      {isMobile ? (
        <>
          {!isDetailPage && !isSignupPage && !isServicePage && (
            <MobileTopBar
              searchModal={searchModal}
              setSearchModal={setSearchModal}
            />
          )}
          <div
            className={`${
              (router.pathname === "/portfolio" ||
                router.pathname === "/service" ||
                (isDetailPage && router?.query?.type === "portfolio")) &&
              "mb-[104px]"
            } ${isSignupPage && "bg-white"} ${
              (router.pathname === "/" ||
                router.pathname === "/login" ||
                router.pathname === "/portfolio" ||
                router.pathname === "/watchlist" ||
                router.pathname === "/learn") &&
              "mt-16 "
            } ${router.pathname === "/signup" && "mt-[0px]"} `}
          >
            {searchModal ? (
              <SearchModal setSearchModal={setSearchModal} />
            ) : (
              children
            )}
          </div>
          {isSearchPage ||
          isSearchListPage ||
          isHighRiskPage ||
          isAssetPage ||
          isRealtimeRiskPage ||
          isPortfolioDetailPage ||
          isWathlistPage ||
          isIndustryPage ||
          isPortfolioRiskComparePage ||
          isPortfolioSharpesPage ||
          isCountryPage ||
          isPortfolioRiskListPage ||
          isDiagnosticPage ||
          isMainRankingPage ||
          isStrategyPage ||
          isKbAccountDetailPage ||
          isTopicPage ||
          isScoreRankingPage ||
          isIndustryRankingPage ||
          isPortfolioContentsPage ? (
            <NotiFooter
              isMobilePaddingAdd={
                isSearchPage || isWathlistPage || isStrategyPage
              }
            />
          ) : (
            (isLoginPage || isMainPage || isLearnPage) && (
              <div className="">
                <Footer />
              </div>
            )
          )}
          {/* {!isDetailPage && <MobileFooter />} */}
          {isB2bPage && <B2bFooter />}
        </>
      ) : (
        <>
          <div className={` flex flex-col w-800:items-center relative`}>
            <TopBar searchModal={searchModal} setSearchModal={setSearchModal} />

            <div
              className={`${
                !isDetailPage
                  ? isSignupPage
                    ? "mt-[64px]"
                    : isLearnPage || isMainPage
                    ? "mt-16"
                    : "mt-20"
                  : "mt-16"
              } ${router.pathname === "/service" && "mb-10"} ${
                (router.pathname === "/portfolio" ||
                  (isDetailPage && router?.query?.type === "portfolio")) &&
                "mb-[80px]"
              }`}
            >
              {searchModal ? (
                <SearchModal setSearchModal={setSearchModal} />
              ) : (
                children
              )}
            </div>

            {isSearchPage ||
            isSearchListPage ||
            isHighRiskPage ||
            isAssetPage ||
            isRealtimeRiskPage ||
            isWathlistPage ||
            isPortfolioDetailPage ||
            isIndustryPage ||
            isPortfolioRiskComparePage ||
            isCountryPage ||
            isPortfolioSharpesPage ||
            isPortfolioRiskListPage ||
            isDiagnosticPage ||
            isStrategyPage ||
            isMainRankingPage ||
            isTopicPage ||
            isScoreRankingPage ||
            isIndustryRankingPage ||
            isPortfolioContentsPage ? (
              <NotiFooter isDesktop={true} />
            ) : (
              !isPortfolioPage &&
              !isSignupPage &&
              !isWatchlistEditPage &&
              !isDetailPage &&
              !isProfileAlarmPage &&
              !isAlarmMethodPage &&
              !isB2bPage &&
              !isMembershipPage && (
                <div className="w-full max-w-[800px]">
                  <Footer />
                </div>
              )
            )}
            {isB2bPage && <B2bFooter />}

            {/* {!isDetailPage && (!isProfilePage || !isTolerancePage) && (
              <MenuBar />
            )} */}
          </div>
        </>
      )}
      {/* {searchModal && <SearchModal setSearchModal={setSearchModal} />} */}
    </>
  );
};

export default Layout;
